import React, { useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function worksList(props) {
  const { projects, selectedClient } = props;

  // State for the list
  const [list, setList] = useState([...projects]);

  return (
    <section className="container works-list pb-6">
      <div className="columns is-flex-wrap-wrap is-justify-content-space-between">
        {list?.map((project, i) => (
          <Link
            key={i}
            to={`/${project?.node?.slug}`}
            className={`column is-clickable ${
              selectedClient === project.node.client?.label ||
              selectedClient === 'All'
                ? 'item-show'
                : 'item-hide'
            }`}
          >
            <GatsbyImage
              as="figure"
              className="is-fullwidth-image image mb-4"
              image={getImage(project.node.MainPlaceholderVideo?.visuel)}
              alt={project.node.MainPlaceholderVideo?.visuel?.description}
            />
            <div className="content">
              <div>
                <p className="subtitle  mb-5 is-4">
                  {project.node.client?.label}
                </p>
                <h3 className="title is-2">{project.node.title}</h3>
              </div>
              <div>
                <span className="button is-transparent">See the case</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
}

export default worksList;
