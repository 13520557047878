import React, { Component } from 'react';

export default class backToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackToTop: false,
      isFixed: true,
      isAbsolute: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollProgress);
  }

  isScrolledIntoView = elem => {
    var docViewTop = window.pageYOffset;
    var docViewBottom = docViewTop + window.innerHeight;

    var elemTop = document.querySelector(elem).offsetTop;
    var elemBottom = elemTop + document.querySelector(elem).offsetHeight;

    return elemTop <= docViewBottom && elemBottom >= docViewTop;
  };

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    // Toggle Show Link
    if (scrollPx > clientHeight) {
      this.setState({
        showBackToTop: true,
      });
    } else {
      this.setState({
        showBackToTop: false,
      });
    }

    // Set position
    if (
      this.isScrolledIntoView('.footer') ||
      (document.querySelector('.other-cases') &&
        this.isScrolledIntoView('.other-cases')) ||
      (document.querySelector('.clients-block') &&
        this.isScrolledIntoView('.clients-block'))
    ) {
      this.setState({
        isFixed: false,
        isAbsolute: true,
      });
    } else {
      this.setState({
        isFixed: true,
        isAbsolute: false,
      });
    }
  };

  goTop = event => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render() {
    return (
      <a
        href="#"
        className={`link go-top ${this.state.showBackToTop ? 'show' : ''} ${
          this.state.isFixed ? 'is-fixed' : ''
        } ${this.state.isAbsolute ? 'is-absolute' : ''}`}
        onClick={this.goTop}
      >
        Back to top <span className="icon-arrow"></span>
      </a>
    );
  }
}
