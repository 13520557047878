import React from 'react';

function HeroTitle({ subtitle, title, catchphrase }) {
  return (
    <section className="hero is-medium">
      <div className="hero-body pt-0 pb-5 is-justify-content-center has-text-centered">
        <div className="">
          {subtitle && <p className="subtitle is-2">{subtitle}</p>}
          {title && <h1 className="title is-1">{title}</h1>}
          {catchphrase && (
            <div dangerouslySetInnerHTML={{ __html: catchphrase }} />
          )}
        </div>
      </div>
    </section>
  );
}

export default HeroTitle;
