import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import WorksList from '../components/worksList/worksList';
import BackToTop from '../components/navigation/backToTop';

class WorkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: 'All',
    };
  }

  // Filter clients
  updateFilterClients = (event, selectedClient) => {
    event.preventDefault();
    this.setState({ selectedClient });
  };

  render() {
    const { data } = this.props;
    const projectsList = data.allContentfulPageProjet.edges;

    return (
      <Layout
        headerCssClass="has-background-white"
        mainCssClass="projects-list has-background-white"
        activeMenuLink="work"
      >
        <SEO titleOnglet="Discover the cases" />

        {/* HeroTitle */}
        <HeroTitle title="Discover the cases" />

        {/* WorkList */}
        <WorksList
          projects={projectsList}
          selectedClient={this.state.selectedClient}
        />

        {/* BackToTop */}
        <BackToTop />
      </Layout>
    );
  }
}

export const query = graphql`
  query ContentfulProjectsList {
    allContentfulPageProjet(
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { ne: "example-ne-pas-supprimer-ni-repasser-en-draft" } }
    ) {
      edges {
        node {
          id
          slug
          title
          client {
            id
            label
          }
          MainPlaceholderVideo {
            visuel {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              description
            }
          }
          catchphrase {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default WorkList;
